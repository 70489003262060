import React from "react";
import g from "glamorous";
import { css } from "glamor";
import Layout from "../../components/layout";
import Menu from "../../components/Menu-en";
import Footer from "../../components/Footer-en";
import { Link } from "gatsby";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";
import { FaHome } from "react-icons/fa";

export default function Techniques({
  data // this prop will be injected by the GraphQL query below.
}) {
  // data.markdownRemark holds our post data

  const mediaQueries = {
    phone: "@media only screen and (max-width: 991px)"
  };

  const WhiteRow = g.section({
    backgroundColor: `white`,
    padding: `2em 0`,
    borderBottom: `1px solid #e9eaeb`
  });

  const GreyRow = g.section({
    backgroundColor: `#f6f7f8`,
    padding: `2em 0`,
    borderBottom: `1px solid #e9eaeb`
  });

  const H1 = g.h1({
    fontSize: `2.9em`,
    letterSpacing: `.08em`,
    fontWeight: `600`,
    marginTop: `2em`,
    color: `#3c4d68`,
    margin: `40px 0 .3em`,
    [mediaQueries.phone]: {
      fontSize: `2.4em`
    }
  });

  const Subtitle = g.div({
    color: `#3c4d68`,
    fontWeight: `400`,
    fontSize: `1.05em`,
    letterSpacing: `.03em`,
    marginBottom: `.5em`
  });

  const cssButton = css({
    color: `white`,
    backgroundColor: `#3c4d68`,
    border: `1px solid #3c4d68`,
    padding: `.5em 1.5em`,
    display: `block`,
    margin: `58px 0 10px`,
    borderRadius: `5px`,
    textAlign: `center`,
    fontWeight: `500`,
    width: `285px`,
    float: `right`,
    textDecoration: `none`,
    letterSpacing: `.03em`,
    transition: `all 0.3s ease-in-out 0s`,
    ":hover": {
      backgroundColor: `white`,
      color: `#3c4d68`,
      transition: `all 0.3s ease-in-out 0s`
    },
    [mediaQueries.phone]: {
      margin: `28px 0 10px`
    }
  });

  const H2 = g.h2({
    fontVariant: `small-caps`,
    fontSize: `1.8em`,
    letterSpacing: `.1em`,
    fontWeight: `500`,
    margin: `0 0 .6em`
  });

  const cssLink = css({
    color: `#2c2e31`,
    textDecoration: `none`,
    fontSize: `1em`,
    borderBottom: `none`,
    transition: `all .3s`,
    padding: `0 0 4px 0`,
    lineHeight: `30px`,
    margin: `0`,
    position: `relative`,
    "::before": {
      content: " ",
      position: `absolute`,
      width: `100%`,
      height: `1px`,
      bottom: `0`,
      left: `0`,
      backgroundColor: `#2c2e31`,
      visibility: `invisible`,
      transform: `scaleX(0)`,
      transition: `all 0.3s ease-in-out 0s`
    },
    ":hover": {
      transition: `all .3s`,
      border: `none`,
      background: `none`,
      padding: `0 0 3px 0`,
      margin: `0`,
      "::before": {
        visibility: `visible`,
        transform: `scaleX(1)`,
        transition: `all 0.3s ease-in-out 0s`
      }
    }
  });

  const cssLinkTitle = css({
    color: `#3c4d68`,
    textDecoration: `none`,
    fontSize: `1em`,
    borderBottom: `none`,
    transition: `all .3s`,
    padding: `0 0 4px 0`,
    lineHeight: `30px`,
    margin: `0`,
    position: `relative`,
    "::before": {
      content: " ",
      position: `absolute`,
      width: `100%`,
      height: `3px`,
      bottom: `0`,
      left: `0`,
      backgroundColor: `#3c4d68`,
      visibility: `invisible`,
      transform: `scaleX(0)`,
      transition: `all 0.3s ease-in-out 0s`
    },
    ":hover": {
      transition: `all .3s`,
      border: `none`,
      background: `none`,
      padding: `0 0 4px 0`,
      margin: `0`,
      "::before": {
        visibility: `visible`,
        transform: `scaleX(1)`,
        transition: `all 0.3s ease-in-out 0s`
      }
    }
  });

  const Breadcrumb = g.ul({
    fontSize: `13px`,
    lineHeight: `20px`,
    marginLeft: `0 !important`,
    marginTop: `0`,
    marginBottom: `10px !important`,
    fontWeight: `500`,
    letterSpacing: `.015em`
  });

  const BreadcrumbItem = g.li({
    display: `inline`,
    "&:first-of-type": {
      "&::before": {
        content: `none`
      }
    },
    "&::before": {
      content: `/`,
      padding: `0 .5em`
    }
  });

  const cssBreadcrumbLink = css({
    color: `#3c4d68`,
    borderBottom: `none`
  });

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>
            Procedures of aesthetic medicine and cosmetic surgery | Dr Cédric
            Kron
          </title>
          <meta
            name="description"
            content="Procedures of aesthetic medicine and plastic surgery performed by Dr Kron in Paris : surgical lifts, botox and filler injections, breast surgery, fat removal technics."
          />
          <html lang="en" />
          <link
            rel="alternate"
            hreflang="fr"
            href="https://www.dr-kron.com/techniques/"
          />
          <script type="application/ld+json">{` {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Procedures",
          "item": "https://www.dr-kron.com/en/procedures/"
          }]
              }
    `}</script>
        </Helmet>
        <Menu current="interventions" pathFr="/techniques/" />
        <main>
          <WhiteRow css={{ paddingBottom: `8px !important` }}>
            <Container>
              <Row>
                <Col>
                  <H1>Procedures</H1>
                  <Subtitle>By type of treatment</Subtitle>
                  <Breadcrumb
                    css={{
                      marginTop: `3em !important`,
                      "@media only screen and (max-width: 600px)": {
                        display: `none`
                      }
                    }}
                  >
                    <BreadcrumbItem>
                      <Link to="/en/" {...cssBreadcrumbLink} title="Homepage">
                        <FaHome
                          css={{
                            fontSize: `1.2em`,
                            paddingBottom: `.2em`,
                            transform: `translateY(5px)`
                          }}
                        />
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>Procedures</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col>
                  <Link {...cssButton} to="/en/treatments/">
                    See treatments (by body region)
                  </Link>
                </Col>
              </Row>
            </Container>
          </WhiteRow>

          <WhiteRow>
            <Container>
              <H2>
                <Link {...cssLinkTitle} to="/en/botox-injections/">
                  Botox injection
                </Link>
              </H2>
              <Row>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/face/bruxism-teeth-grinding/">
                    Bruxism: Teeth grinding treatment
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/face/jaw-reduction-botox/">
                    Jaw reduction using Botox
                  </Link>
                </Col>
              </Row>
            </Container>
          </WhiteRow>
          <GreyRow>
            <Container>
              <H2>
                <Link {...cssLinkTitle} to="/en/fat-removal/">
                  Fat removal
                </Link>
              </H2>
              <Row>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/body-contouring/body-lift/">
                    Body lift
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/body-contouring/cryolipolysis-coolsculpting/"
                  >
                    Coolsculpting
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/body-contouring/emsculpt/">
                    EMSculpt
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/body-contouring/keybody-lipo/">
                    Keybody-Lipo
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/body-contouring/liposuction/">
                    Liposuction
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/body-contouring/xbody-reshape-body-ems/"
                  >
                    XBody
                  </Link>
                </Col>
              </Row>
            </Container>
          </GreyRow>
          <WhiteRow>
            <Container>
              <Row>
                <Col>
                  <H2>
                    <Link {...cssLinkTitle} to="/en/hyaluronic-acid/">
                      Hyaluronic acid
                    </Link>
                  </H2>
                </Col>
              </Row>
              <Row>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/face/hyaluronic-acid-wrinkles/">
                    Correction of wrinkles
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/genital/g-spot-amplification/">
                    G-Spot Amplification
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/face/skinbooster/">
                    Skinboosters
                  </Link>
                </Col>
              </Row>
            </Container>
          </WhiteRow>
          <GreyRow>
            <Container>
              <Row>
                <Col>
                  <H2>
                    <Link {...cssLinkTitle} to="/en/implants/">
                      Implants
                    </Link>
                    /{" "}
                    <Link {...cssLinkTitle} to="/en/lipotransfer/">
                      Lipofilling
                    </Link>
                  </H2>
                </Col>
              </Row>
              <Row>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/breast/breast-lipofilling/">
                    Breast Lipofilling
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/breast/breast-implants/">
                    Breast implants
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/body-contouring/buttock-plastic-surgery/"
                  >
                    Buttock plastic surgery
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/ears/earfold-prominent-ears-treatment/"
                  >
                    EarFold implants
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/genitals/labiaplasty-labia-reduction/"
                  >
                    Labiaplasty
                  </Link>
                </Col>
              </Row>
            </Container>
          </GreyRow>

          <WhiteRow>
            <Container>
              <H2>
                <Link {...cssLinkTitle} to="/en/plastic-surgery/">
                  Plastic surgery
                </Link>
              </H2>
              <Row>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/before-after/adipomastia-gynecomastia/"
                  >
                    Adipomastia - Gynecomastia
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/breast/breast-reduction/">
                    Breast reduction
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/eyes/blepharoplasty-eyelid-surgery/"
                  >
                    Blepharoplasty: Eyelid surgery
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/body-contouring/body-lift/">
                    Body lift
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/body-contouring/buttock-plastic-surgery/"
                  >
                    Buttock plastic surgery
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/nose/rhinoplasty/">
                    Rhinoplasty
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/nose/septoplasty/">
                    Septoplasty
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/nose/septoplasty/">
                    Deviated nasal septum correction
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/ears/otoplasty-prominent-ears-surgery/"
                  >
                    Prominent Ear surgery
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/ears/otoplasty-prominent-ears-surgery/"
                  >
                    Otoplasty
                  </Link>
                </Col>
              </Row>
            </Container>
          </WhiteRow>

          <GreyRow>
            <Container>
              <Row>
                <Col>
                  <H2>
                    <Link {...cssLinkTitle} to="/en/reconstructive-surgery/">
                      Reconstructive surgery
                    </Link>
                  </H2>
                </Col>
              </Row>
              <Row>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/breast/breast-lift/">
                    Breast lift
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/breast/breast-reconstruction-surgery/"
                  >
                    Breast Reconstruction surgery
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/arms/carpal-tunnel-syndrome/">
                    Carpal tunnel syndrome
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/arms/decompression-ulnar-nerve/">
                    Decompression of the ulnar nerve
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/ears/split-torn-stretched-earlobe-surgery/"
                  >
                    Earlobe surgery
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/arms/carpal-tunnel-syndrome/">
                    Median nerve decompression
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/skin/scar-revision/">
                    Scar revision
                  </Link>
                </Col>
              </Row>
            </Container>
          </GreyRow>

          <WhiteRow>
            <Container>
              <H2>
                <Link {...cssLinkTitle} to="/skin/">
                  Skin treatments
                </Link>
              </H2>
              <Row>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/skin/biorevitalization-peel-prx-t33/"
                  >
                    Biorevitalization Peel PRX-T33
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/face/crows-feet-chemical-peel/">
                    Chemical peel for crow's feet
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/visage/face-treatment-diagnosis/">
                    Diagnosis for face treatments
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/face/hydrafacial/">
                    HydraFacial
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/skin/keybody-skin/">
                    Keybody-Skin
                  </Link>
                </Col>

                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link
                    {...cssLink}
                    to="/en/skin/laser-icon-skin-imperfections/"
                  >
                    Laser Icon
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/face/mesolift-nctf/">
                    Mesolift NCTF
                  </Link>
                </Col>

                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/face/skinbooster/">
                    Skinbooster
                  </Link>
                </Col>
              </Row>
            </Container>
          </WhiteRow>
          <GreyRow css={{ paddingBottom: `6em` }}>
            <Container>
              <Row>
                <Col>
                  <H2>
                    <Link {...cssLinkTitle} to="/en/lifts/">
                      Surgical lifts
                    </Link>
                  </H2>
                </Col>
              </Row>
              <Row>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/arms/arm-lift/">
                    Arm Lift
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/breast/breast-lift/">
                    Breast lift
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/face/facelift-lvpa/">
                    Facelift LVPA
                  </Link>
                </Col>
                <Col span={{ xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 3 / 12 }}>
                  <Link {...cssLink} to="/en/figure/tummy-tuck/">
                    Tummy Tuck
                  </Link>
                </Col>
              </Row>
            </Container>
          </GreyRow>
        </main>
        <Footer />
      </g.Div>
    </Layout>
  );
}
